












































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Bolletta, BollettaCreateBody, BollettaReturned, ContrattiReturned, Fabbricato, Ospite, PostoLetto, Stanza } from "operatn-api-client";
import { ActionTypes, AlertType } from "@/store";

import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import BolletteHandlerMixin from "@/mixins/handlers/BolletteHandlerMixin";
import TipoBollettaHandlerMixin from "@/mixins/handlers/TipoBollettaHandlerMixin";
import ContrattoHandlerMixin from "@/mixins/handlers/ContrattoHandlerMixin";
import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";

import { RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";
import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnBollettaForm from "@/components/gears/forms/OperatnBollettaForm.vue";
import OperatnContrattoInfo from "@/components/gears/infos/OperatnContrattoInfo.vue";
import OperatnOspiteInfo from "@/components/gears/infos/OperatnOspiteInfo.vue";
import OperatnAddDataIncassoForm from "@/components/gears/forms/OperatnAddDataIncassoForm.vue";

type OspiteAndStanze = {
  ospite: Ospite;
  postiLetto: (PostoLetto & { stanza: Stanza & { fabbricato: Fabbricato } })[];
};

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnContrattoInfo,
    OperatnBollettaForm,
    OperatnAddDataIncassoForm,
    OperatnOspiteInfo,
  },
})
export default class ContrattoId extends Mixins<
  ResourceManagerMixin<Bolletta, BollettaCreateBody, Bolletta, number> & BolletteHandlerMixin & ContrattoHandlerMixin & OspiteHandlerMixin & TipoBollettaHandlerMixin
>(ResourceManagerMixin, BolletteHandlerMixin, ContrattoHandlerMixin, OspiteHandlerMixin, TipoBollettaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Number, required: false })
  cid!: number;

  /* DATA */

  protected askDeleteText = "";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare le bollette selezionate?";
  private endedMoreThanAMonthAgo = true;
  private detailsExpanded = 0;
  protected ospitiAndPostiLetto: OspiteAndStanze[] = [];
  protected contratto: ContrattiReturned | null = null;
  protected stanza: Stanza | null = null;
  protected fabbricato: Fabbricato | null = null;
  protected infoDataLoaded = false;

  private bollettaDaAggiungereDataIncasso: Bolletta | null = null;
  private showIncassoDialog = false;
  private bollettaDataIncasso: Date | null = null;
  private bollettaDataIncassoValid = false;

  /* GETTERS AND SETTERS */

  get columns(): Column<Bolletta>[] {
    return [
      {
        text: "Id",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Tipo bolletta",
        value: "tipoBolletta.tipoBolletta",
        groupable: false,
        editable: false,
      },
      {
        text: "Contabilizzata",
        value: "dataInvioEusis",
        groupable: false,
        editable: false,
        itemTextHandler: (date: string) => (date ? new Date(date).toLocaleDateString() : "No"),
      },
      {
        text: "Totale",
        value: "importoTotale",
        groupable: false,
        editable: false,
        itemTextHandler: (price: string) => (price ? `${price}€` : "-"),
      },
      {
        text: "Canoni",
        value: "importoCanoni",
        groupable: false,
        editable: false,
        itemTextHandler: (price: string) => (price ? `${price}€` : "-"),
      },
      {
        text: "Consumi",
        value: "importoConsumi",
        groupable: false,
        editable: false,
        itemTextHandler: (price: string) => (price ? `${price}€` : "-"),
      },
      {
        text: "Competenza dal",
        value: "competenzaDal",
        groupable: false,
        editable: false,
        itemTextHandler: (date: string) => (date ? new Date(date).toLocaleDateString() : ""),
      },
      {
        text: "Competenza al",
        value: "competenzaAl",
        groupable: false,
        editable: false,
        itemTextHandler: (date: string) => (date ? new Date(date).toLocaleDateString() : ""),
      },
      {
        text: "D. Scadenza",
        value: "dataScadenza",
        groupable: false,
        editable: false,
        itemTextHandler: (date: string) => (date ? new Date(date).toLocaleDateString() : ""),
      },
      {
        text: "Data Incasso",
        value: "dataIncasso",
        groupable: false,
        editable: false,
        itemTextHandler: (date: string) => (date ? new Date(date).toLocaleDateString() : ""),
      },
      {
        text: "Q",
        value: "quietanziante.quietanziante",
        groupable: false,
        editable: false,
      },
    ];
  }

  get exportedHeaders() {
    return this.columns ? this.getExportHeadersFromCols(this.columns) : {};
  }

  get utentiNomiUtente(): string[] {
    return [];
  }

  get actions(): Actions<Bolletta> {
    return {
      others: [
        {
          icon: "mdi-delete",
          color: "red",
          title: "Elimina",
          showAction: (item) => item.siglaCausale !== 'B' && item.dataInvioEusis === null,
          action: (item) => {
            this.askDeleteText = this.getAskDeleteStornaText(false, item.id, item.importoTotale);
            this.askDelete(item);
          },
        },
        {
          icon: "mdi-arrow-left-bold",
          color: "red",
          title: "Storna",
          showAction: (item) => item.siglaCausale !== 'B' && item.dataInvioEusis !== null,
          action: (item) => this.askStorna(item),
        },
        {
          icon: "mdi-marker-check",
          color: "primary",
          title: "Incassa",
          action: (item) => this.openIncassaDialog(item),
          showAction: (item) => item.dataInvioEusis !== null && item.dataIncasso === null,
        },
      ],
    };
  }

  /* METHODS */

  private getAskDeleteStornaText(isStorna: boolean, id: number, importoTotale: number): string {
    return `Sei sicuro di voler ${isStorna ? "stornare" : "eliminare"} la bolletta di ${importoTotale}€ con id ${id}? `;
  }

  askStorna(bolletta: Bolletta): void {
    this.$store.dispatch(ActionTypes.SHOW_CONFIRM_DIALOG, {
      text: this.getAskDeleteStornaText(true, bolletta.id, bolletta.importoTotale),
      callback: async (answer) => {
        if (answer) {
          try {
            if (bolletta.dataInvioEusis) {
              await this.stornaHandler(bolletta.id, false);
              this.updateBollette();
            }
          } catch (error) {}
        }
      },
    });
  }

  openIncassaDialog(bolletta: Bolletta): void {
    this.bollettaDaAggiungereDataIncasso = bolletta;
    this.showIncassoDialog = true;
    this.bollettaDataIncasso = null;
    this.bollettaDataIncassoValid = false;
  }
  incassaDialogCancel(): void {
    this.showIncassoDialog = false;
    this.bollettaDaAggiungereDataIncasso = null;
    this.bollettaDataIncasso = null;
  }
  async incassaDialogConfirm(): Promise<void> {
    if (this.bollettaDataIncassoValid) {
      try {
        await this.incassaBolletta(this.cid, this.bollettaDaAggiungereDataIncasso?.id as number, { dataIncasso: this.bollettaDataIncasso as Date });
      } finally {
        const b = this.values.find((b) => b.id === this.bollettaDaAggiungereDataIncasso?.id);
        if (b) {
          b.dataIncasso = this.bollettaDataIncasso;
        }
        this.incassaDialogCancel();
      }
    } else {
      this.incassaDialogCancel();
    }
  }

  async stornaHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.stornaBolletta(this.cid, id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteBolletta(this.cid, id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: BollettaCreateBody): Promise<number> {
    value.idContratto = this.cid;
    return this.createBolletta(this.cid, value);
  }

  async tupleValueFromCreateBody(id: number, _body: BollettaCreateBody): Promise<Bolletta> {
    return await this.getBollettaById(this.cid, id);
  }

  rowBackgrounds(item: BollettaReturned): RowColors {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const dataScadenza = new Date(item.dataScadenza);
    const dataIncasso = item.dataIncasso ? new Date(item.dataIncasso) : null;

    if (item.dataInvioEusis !== null) {
      if (dataIncasso !== null) {
        return "soft-green";
      } else if (dataScadenza >= now) {
        return "soft-blue";
      } else {
        return "soft-red";
      }
    }
    
    return "";
  }

  async updateBollette() {
    this.values = await this.getBollette(this.cid);
  }

  /* LIFE CYCLE */

  async created() {
    this.updateBollette();

    this.contratto = await this.getContratto(this.cid, {
      tariffa: { tipoTariffa: true, tipoOspite: true },
      tipoContratto: true,
      quietanziante: true,
      contrattiSuOspite: {
        ospite: true,
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: {
            stanza: {
              fabbricato: true,
            },
          },
        },
      },
    });
    this.contratto.quietanziante?.quietanziante;
    const maxEndDate = new Date();
    maxEndDate.setMonth(maxEndDate.getMonth() - 1);
    if (this.contratto.dataFine >= maxEndDate) {
      this.endedMoreThanAMonthAgo = false;
    }

    this.ospitiAndPostiLetto =
      this.contratto.contrattiSuOspite?.map((x) => ({
        ospite: x.ospite as Ospite,
        postiLetto: x.contrattiSuOspiteSuPostoLetto?.map((x) => x.postoLetto as PostoLetto & { stanza: Stanza & { fabbricato: Fabbricato } }) ?? [],
      })) ?? [];

    this.infoDataLoaded = true;
  }
}
