



































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody, Tariffa, TipiOspiteReturned, TipoFabbricato, TipoStanza, TipoTariffa } from "operatn-api-client";

import TipoFabbricatoHandlerMixin from "@/mixins/handlers/TipoFabbricatoHandlerMixin";
import TipoOspiteHandlerMixin from "@/mixins/handlers/TipoOspiteHandlerMixin";
import TipoTariffaHandlerMixin from "@/mixins/handlers/TipoTariffaHandlerMixin";
import TipoStanzaHandlerMixin from "@/mixins/handlers/TipoStanzaHandlerMixin";
import TariffaHandlerMixin from "@/mixins/handlers/TariffaHandlerMixin";
import { AlertType } from "@/store";

interface Body {
  idTipoOspite: null | number;
  idUtilizzoStanza: null | number;
  idTipoFabbricato: null | number;
  idTipoTariffa: null | number;
}

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {},
})
export default class OperatnTariffa extends Mixins(
  TipoFabbricatoHandlerMixin,
  TipoOspiteHandlerMixin,
  TipoTariffaHandlerMixin,
  TipoStanzaHandlerMixin,
  TariffaHandlerMixin
) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ validator: (v) => v === null || typeof v === "number", default: () => null })
  selectedIdTipoStanza!: number | null;

  @Prop({ validator: (v) => v === null || typeof v === "number", default: () => null })
  selectedIdTipoFabbricato!: number | null;

  /* DATA */

  private tipiFabbricato: TipoFabbricato[] = [];
  private tipiStanza: TipoStanza[] = [];
  private tipiOspite: TipiOspiteReturned[] = [];
  private tipiTariffa: TipoTariffa[] = [];
  private tariffe: Tariffa[] = [];

  private tipoTariffaDefault: TipoTariffa | null = null;
  private body: Body | null = null;
  private selectedTariffa: null | Tariffa = null;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get tipiOspiteItems() {
    return this.tipiOspite.map((to) => ({
      label: `${to.sigla} - ${to.tipoOspite}`,
      value: to.id,
    }));
  }

  /* WATCH */

  @Watch('selectedIdTipoStanza')
  watchSelectedIdTipoStanza() {
    if (this.body && this.selectedIdTipoStanza !== null) {
      this.body.idUtilizzoStanza = this.selectedIdTipoStanza;
    }
  }

  @Watch('selectedIdTipoFabbricato')
  watchSelectedIdTipoFabbricato() {
    if (this.body && this.selectedIdTipoFabbricato !== null) {
      this.body.idTipoFabbricato = this.selectedIdTipoFabbricato;
    }
  }

  @Watch("body", { deep: true })
  watchBody() {
    if (this.body && this.tariffe.length) {
      this.selectedTariffa =
        this.tariffe.find(
          (t) =>
            t.idTipoOspite === (this.body as any).idTipoOspite &&
            t.idUtilizzoStanza === (this.body as any).idUtilizzoStanza &&
            t.idTipoFabbricato === (this.body as any).idTipoFabbricato &&
            t.idTipoTariffa === (this.body as any).idTipoTariffa
        ) ?? null;
    }
  }

  @Watch("selectedTariffa", { deep: true })
  watchSelectedTariffa() {
    if (this.tariffe.length) {
      this.internalValue.idTariffa = this.selectedTariffa ? this.selectedTariffa.id : undefined;
      this.$emit("update:formValid", !!this.selectedTariffa);
    }
  }

  @Watch("internalValue", { deep: true })
  watchValue() {
    const tariffa = this.tariffe.find((t) => t.id === this.internalValue.idTariffa);
    this.body = tariffa
      ? {
          idTipoOspite: tariffa.idTipoOspite,
          idUtilizzoStanza: tariffa.idUtilizzoStanza,
          idTipoFabbricato: tariffa.idTipoFabbricato,
          idTipoTariffa: tariffa.idTipoTariffa,
        }
      : this.getEmptyBody();
  }

  @Watch("tipoTariffaDefault")
  watchSelectedTipoTariffaDefault() {
    if (this.body && typeof this.body.idTipoTariffa !== "number") {
      this.body.idTipoTariffa = this.tipoTariffaDefault?.id ?? null;
    }
  }

  /* METHODS */

  getEmptyBody() {
    return {
      idTipoOspite: null as number | null,
      idUtilizzoStanza: this.selectedIdTipoStanza ?? null as number | null,
      idTipoFabbricato: this.selectedIdTipoFabbricato ?? null as number | null,
      idTipoTariffa: this.tipoTariffaDefault?.id as number | null,
    };
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiFabbricato = await this.getTipiFabbricato(AlertType.ERRORS_QUEUE);
    this.tipiStanza = await this.getTipiStanza(AlertType.ERRORS_QUEUE);
    this.tipiTariffa = await this.getTipiTariffa(AlertType.ERRORS_QUEUE);
    this.tipiOspite = await this.getTipiOspite({}, AlertType.ERRORS_QUEUE);
    this.tariffe = await this.getTariffe({}, AlertType.ERRORS_QUEUE);

    this.tipoTariffaDefault = await this.getTipoTariffaByValue("MENSILE", AlertType.ERRORS_QUEUE);

    this.body = typeof this.internalValue.idTariffa !== "number" ? this.getEmptyBody() : this.tariffe.find((t) => t.id === this.internalValue.idTariffa) ?? null;
  }
}
