












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody } from "operatn-api-client";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {},
})
export default class OperatnTariffa extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }
}
