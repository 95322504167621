






















































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ContrattiPDFTypes, Contratto } from "operatn-api-client";
import clipboardCopy from "clipboard-copy";
import axios from "axios";

import { downloadBlob } from "@/utils";
import { ActionTypes, AlertType } from "@/store";

@Component
export default class OperatnContrattoInfo extends Vue {
  /* PROPS */

  @Prop({ type: Object, required: true })
  contratto!: Contratto;

  /* METHODS */

  downloadContratto() {
    this.downloadPdf(this.contratto.id, ContrattiPDFTypes.CONTRATTO);
  }
  downloadChiusuraAnticipata() {
    this.downloadPdf(this.contratto.id, ContrattiPDFTypes.CHIUSURA_ANTICIPATA);
  }

  async downloadPdf(id: number, type: ContrattiPDFTypes): Promise<void> {
    try {
      const filename = type === ContrattiPDFTypes.CONTRATTO ? `${id}.pdf` : `${id}_chiusura.pdf`;
      const path = this.$stored.getPath(`contratti/${filename}`);
      const response = await axios.get(path, { responseType: "blob" });
      const blob: Blob = response.data;
      downloadBlob(blob, filename);
    } catch (error) {
      this.$store.dispatch(ActionTypes.ALERT, { message: `File non trovato`, alertType: AlertType.ERRORS_QUEUE });
    }
  }

  parseDate(date: Date | null): string {
    return date ? date.toLocaleDateString() : ' - ';
  }

  copyText(text: string | null | undefined): void {
    if (text === null || text === undefined) {
      text = "";
    }
    clipboardCopy(text);
    this.$store.dispatch(ActionTypes.SET_TOAST, { message: "Testo copiato negli appunti", color: "success" });
  }
}
