





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody } from "operatn-api-client";

@Component({
  model: {
    prop: "value",
    event: "save",
  }
})
export default class OperatnTipoRata extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

}
