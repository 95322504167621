


























































import { Component, Mixins, Watch } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiFilterParams, ContrattiReplaceBody, ContrattiReturned, RuoloUtente } from "operatn-api-client";

import { AlertType } from "@/store";

import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import ContrattoHandlerMixin from "@/mixins/handlers/ContrattoHandlerMixin";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnContrattoForm from "@/components/gears/forms/contratto/OperatnContrattoForm.vue";
import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnOspiteInput from "@/components/gears/inputs/OperatnOspiteInput.vue";
import { RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";

interface Tuple {
  id: number;
  dataInizio: Date;
  dataFine: Date;
  nome: string;
  cognome: string;
  tipoContratto: string;
  codiceFabbricato: string;
  unitaImmobiliare: string;
  numeroStanza: string;
  postiLetto: string;
  nOspiti: number;
  dataInserimento: Date;
  reference: ContrattiReturned;
  idQuietanziante: number;
}

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnContrattoForm,
    OperatnDateInput,
    OperatnOspiteInput,
  },
})
export default class Contratti extends Mixins<ResourceManagerMixin<Tuple, ContrattiCreateBody, ContrattiReplaceBody, number, Tuple> & ContrattoHandlerMixin>(
  ResourceManagerMixin,
  ContrattoHandlerMixin
) {
  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questo contratto?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare i contratti selezionati?";

  private contratti: ContrattiReturned[] = [];
  private dateQueryParams: Pick<ContrattiFilterParams, "dataInizio" | "dataFine"> = {
    dataInizio: undefined,
    dataFine: undefined,
  };
  private ospiteQueryParam: number | null = null;
  private hasQuietanzianteQueryParam: string | null = null;
  private tableLoading = false;

  /* GETTERS AND SETTERS */

  get canAdd(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get canEdit(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get columns(): Column<Tuple>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Data inserimento",
        value: "dataInserimento",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,
        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "Data inizio",
        value: "dataInizio",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "Data fine",
        value: "dataFine",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,
        editable: false,
      },
      {
        text: "Cognome",
        value: "cognome",
        groupable: false,
        editable: false,
      },
      {
        text: "Tipo",
        value: "tipoContratto",
        groupable: false,
        editable: false,
      },
      {
        text: "Fabbricato",
        value: "codiceFabbricato",
        groupable: false,
        editable: false,
      },
      {
        text: "Numero stanza",
        value: "numeroStanza",
        groupable: false,
        editable: false,
      },
      {
        text: "N. ospiti",
        value: "nOspiti",
        groupable: false,
        editable: false,
      },
      {
        text: "Q",
        value: "idQuietanziante",
        groupable: false,
        editable: false,
        itemIcon: true,
        itemIconHandler: (value) => (value !== 1 ? "mdi-check" : ""),
        itemTextHandler: () => "",
        itemIconColour: () => "success",
      },
    ];
  }

  get actions(): Actions<Tuple> {
    return {
      onView: (item) => `../contratti/${item.id}`,
      onEdit: this.canEdit ? (item) => this.openEdit(item) : undefined,
      onDelete: this.canEdit ? (item) => this.askDelete(item) : undefined,
    };
  }

  get queryParams(): ContrattiFilterParams {
    return {
      ...this.dateQueryParams,
      idOspite: this.ospiteQueryParam ?? undefined,
      hasQuietanziante: this.hasQuietanzianteQueryParam === "SI" ? true : this.hasQuietanzianteQueryParam === "NO" ? false : undefined,
    };
  }

  get exportedHeaders() {
    return this.columns ? this.getExportHeadersFromCols(this.columns) : {};
  }

  /* METHODS */

  rowBackgrounds(item: Tuple): RowColors {
    if (item.reference.dataInvioEmail) {
      return "soft-blue";
    }

    return "";
  }

  contrattoValueToTuple(contratto: ContrattiReturned): Tuple {
    return {
      id: contratto.id,
      dataInizio: contratto.dataInizio,
      dataFine: contratto.dataFine,
      nome: contratto.contrattiSuOspite?.[0].ospite?.persona?.nome ?? "",
      cognome: contratto.contrattiSuOspite?.[0].ospite?.persona?.cognome ?? "",
      tipoContratto: contratto.tipoContratto?.sigla ?? "",
      codiceFabbricato: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.fabbricato?.codice ?? "",
      unitaImmobiliare: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.unitaImmobiliare ?? "",
      numeroStanza: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.numeroStanza ?? "",
      postiLetto: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto
        ? contratto.contrattiSuOspite[0].contrattiSuOspiteSuPostoLetto.map((pl) => pl.postoLetto).join(", ")
        : "",
      nOspiti: contratto.contrattiSuOspite?.length ?? 0,
      dataInserimento: contratto.dataInserimento,
      reference: contratto,
      idQuietanziante: contratto.idQuietanziante,
    };
  }

  getIdFromValue(value: Tuple): number {
    return value.reference.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteContratto(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: ContrattiCreateBody): Promise<number> {
    return this.createContratto(value);
  }

  async updateHandler(id: number, value: ContrattiReplaceBody, isTableEdit: boolean): Promise<void> {
    await this.updateContratto(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  updateBodyFromValue(value: Tuple): ContrattiReplaceBody {
    return {
      dataInizio: value.reference.dataInizio,
      dataFine: value.reference.dataFine,
      checkout: !!value.reference.checkout,
      cauzione: !!value.reference.cauzione,
      tipoRata: value.reference.tipoRata,
      idTariffa: value.reference.idTariffa,
      idTipoContratto: value.reference.idTipoContratto,
      idQuietanziante: value.reference.idQuietanziante,
      ospiti: value.reference.contrattiSuOspite
        ? value.reference.contrattiSuOspite.map((cso) => ({
            idOspite: cso.idOspite,
            postiLetto: cso.contrattiSuOspiteSuPostoLetto ? cso.contrattiSuOspiteSuPostoLetto.map((pl) => pl.idPostoLetto) : [],
          }))
        : [],
      note: value.reference.note,
    };
  }
  async tupleValueFromCreateBody(id: number, _body: ContrattiCreateBody): Promise<Tuple> {
    const contratto = await this.getContratto(id, {
      tipoContratto: true,
      contrattiSuOspite: {
        ospite: { persona: true },
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: true,
        },
      },
    });
    return this.contrattoValueToTuple(contratto);
  }
  async tupleValueFromUpdateBody(id: number, _body: ContrattiReplaceBody): Promise<Tuple> {
    const contratto = await this.getContratto(id, {
      tipoContratto: true,
      contrattiSuOspite: {
        ospite: { persona: true },
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: true,
        },
      },
    });
    return this.contrattoValueToTuple(contratto);
  }

  async fetchContratti(): Promise<void> {
    try {
      this.tableLoading = true;
      this.contratti = await this.getContrattiDaFirmare({
        tipoContratto: true,
        contrattiSuOspite: {
          ospite: { persona: true },
          contrattiSuOspiteSuPostoLetto: {
            postoLetto: true,
          },
        },
        ...this.queryParams,
      });
    } finally {
      this.tableLoading = false;
    }
  }

  /* WATCH */

  @Watch("contratti")
  watchContratti() {
    this.values = this.contratti.map((c) => this.contrattoValueToTuple(c));
  }

  @Watch("queryParams", { deep: true })
  async watchQueryParams() {
    await this.fetchContratti();
  }

  /* LIFE CYCLE */

  async mounted() {
    await this.fetchContratti();
  }
}
