





































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { BollettaCreateBody, Quietanziante, TipiBollettaReturned } from "operatn-api-client";

import BolletteHandlerMixin from '@/mixins/handlers/BolletteHandlerMixin';
import TipoBollettaHandlerMixin from '@/mixins/handlers/TipoBollettaHandlerMixin';
import QuietanzianteHandlerMixin from '@/mixins/handlers/QuietanzianteHandlerMixin';

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
  },
})
export default class OperatnBollettaForm extends Mixins(BolletteHandlerMixin, TipoBollettaHandlerMixin, QuietanzianteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<BollettaCreateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private tipiBolletta: TipiBollettaReturned[] = [];
  private quetanzianti: Quietanziante[] = [];
  private importoCanoni?: number | null = null;
  private importoConsumi?: number | null = null;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get tipoBolletta() {
    return this.internalValue.idTipoBolletta;
  }
  set tipoBolletta(value) {
    this.internalValue.idTipoBolletta = value;
    const tipoBollettaSelected = this.tipiBolletta.find(b => b.id === value) ?? null;
    this.importoCanoni = tipoBollettaSelected?.importoCanone ?? undefined;
    this.importoConsumi = tipoBollettaSelected?.importoConsumi ?? undefined;
    if (tipoBollettaSelected?.idQuietanziante) {
      this.internalValue.idQuietanziante = tipoBollettaSelected?.idQuietanziante ?? undefined;
    } else {
      this.internalValue.idQuietanziante = undefined
    }
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<BollettaCreateBody> {
    return {
      dataScadenza: undefined,
      idTipoBolletta: undefined,
      idContratto: undefined,
      idQuietanziante: undefined,
      id: undefined,
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiBolletta = await this.getTipiBolletta();
    this.quetanzianti = await this.getQuietanzianti();
  }
}
