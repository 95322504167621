














































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { TipoStudente, TipiContrattoCreateBody, TipiContrattoReplaceBody } from "operatn-api-client";

import TipoStudenteHandlerMixin from "@/mixins/handlers/TipoStudenteHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnTipoContrattoForm extends Mixins(TipoStudenteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<TipiContrattoCreateBody> | Partial<TipiContrattoReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  tipiContrattoSigle!: string[];

  @Prop({ type: Array, default: () => [] })
  tipiContrattoValues!: string[];

  /* DATA */

  private tipiStudente: TipoStudente[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<TipiContrattoCreateBody> | Partial<TipiContrattoReplaceBody> {
    return {
      sigla: undefined,
      nome: undefined,
      tipoStudente: undefined
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiStudente = await this.getTipiStudente();
  }
}
