
import { Component, Vue } from "vue-property-decorator";
import { BollettaReturned, BollettaCreateBody, InvalidPathParamError, NotFoundError, BadRequestError, InvalidBodyError, InvalidActionError, BollettaIncassaBody } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class BolletteHandlerMixin extends Vue {

  async getBollette(cid: number): Promise<BollettaReturned[]> {
    try {
      return this.$api.contratti.bollette(cid).getAll({ tipoBolletta: true, quietanziante: true })
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare le bollete` });
      }
      throw error;
    }
  }

  async getBollettaById(cid: number, id: number): Promise<BollettaReturned> {
    try {
      return this.$api.contratti.bollette(cid).getById(id, { tipoBolletta: true })
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare le bollete` });
      }
      throw error;
    }
  }

  async incassaBolletta(cid: number, id: number, body: BollettaIncassaBody): Promise<void> {
    try {
      this.$api.contratti.bollette(cid).incassa(id, body);
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare le bollete` });
      }
      throw error;
    }
  }

  async createBolletta(cid: number, body: BollettaCreateBody, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      const id = await this.$api.contratti.bollette(cid).create(body, { alertType });
      return id;
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati bolletta non validi`, alertType });
        } else if (error instanceof InvalidActionError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: error.message, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async stornaBolletta(cid: number, id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.contratti.bollette(cid).storna(id, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Id bolletta non valido`, alertType });
        } else if (error instanceof InvalidActionError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: error.message, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Bolletta non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  }

  async deleteBolletta(cid: number, id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.contratti.bollette(cid).delete(id, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Id bolletta non valido`, alertType });
        } else if (error instanceof InvalidActionError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: error.message, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Bolletta non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  }
}