






























































import { Component, Mixins, Watch } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiFilterParams, ContrattiPDFTypes, ContrattiReplaceBody, ContrattiReturned, RuoloUtente } from "operatn-api-client";
import axios from "axios";

import { ActionTypes, AlertType } from "@/store";
import { downloadBlob } from "@/utils";

import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import ContrattoHandlerMixin from "@/mixins/handlers/ContrattoHandlerMixin";

import { RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnContrattoForm from "@/components/gears/forms/contratto/OperatnContrattoForm.vue";
import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnOspiteInput from "@/components/gears/inputs/OperatnOspiteInput.vue";


interface Tuple {
  id: number;
  dataInizio: Date;
  dataFine: Date;
  nome: string;
  cognome: string;
  tipoContratto: string;
  codiceFabbricato: string;
  unitaImmobiliare: string;
  numeroStanza: string;
  postiLetto: string;
  dataRisposta: Date | null;
  dataChiusuraAnticipata: Date | null;
  reference: ContrattiReturned;
  idQuietanziante: number;
}

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnContrattoForm,
    OperatnDateInput,
    OperatnOspiteInput
  },
})
export default class ContrattiDaVisionare extends Mixins<ResourceManagerMixin<Tuple, ContrattiCreateBody, ContrattiReplaceBody, number> & ContrattoHandlerMixin>(
  ResourceManagerMixin,
  ContrattoHandlerMixin
) {
  /* DATA */

  private contratti: ContrattiReturned[] = [];
  private dateQueryParams: ContrattiFilterParams = {
    dataInizio: undefined,
    dataFine: undefined,
  };
  private ospiteQueryParam: number | null = null;
  private tableLoading = false;
  private hasQuietanzianteQueryParam: string | null = null;

  /* GETTERS AND SETTERS */

  get canAdd(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get canEdit(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get columns(): Column<Tuple>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Data inizio",
        value: "dataInizio",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "Data fine",
        value: "dataFine",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "C. anticipata",
        value: "dataChiusuraAnticipata",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => (value ? value.toLocaleDateString() : "NO"),
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,
        editable: false,
      },
      {
        text: "Cognome",
        value: "cognome",
        groupable: false,
        editable: false,
      },
      {
        text: "Tipo",
        value: "tipoContratto",
        groupable: false,
        editable: false,
      },
      {
        text: "Fabbricato",
        value: "codiceFabbricato",
        groupable: false,
        editable: false,
      },
      {
        text: "N. stanza",
        value: "numeroStanza",
        groupable: false,
        editable: false,
      },
      {
        text: "Data risposta",
        value: "dataRisposta",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,
        editable: false,
        itemTextHandler: (value) => (value ? value.toLocaleDateString() : ""),
      },
      {
        text: "Q",
        value: "idQuietanziante",
        groupable: false,
        editable: false,
        itemIcon: true,
        itemIconHandler: (value) => (value !== 1 ? "mdi-check" : ""),
        itemTextHandler: () => "",
        itemIconColour: () => "success",
      },
    ];
  }

  get exportedHeaders() {
    return this.columns ? this.getExportHeadersFromCols(this.columns) : {};
  }

  get actions(): Actions<Tuple> {
    return {
      onView: (item) => `../contratti/${item.id}`,
      others: [
        {
          icon: "mdi-download",
          color: "primary",
          action: (item) => this.downloadContratto(item),
        },
        {
          icon: "mdi-check",
          color: "success",
          showAction: () => this.canEdit,
          action: (item, index) => this.answerContratto(item, index, true),
        },
        {
          icon: "mdi-close",
          color: "error",
          showAction: () => this.canEdit,
          action: (item, index) => this.answerContratto(item, index, false),
        },
      ],
    };
  }

  get queryParams(): ContrattiFilterParams {
    return {
      ...this.dateQueryParams,
      idOspite: this.ospiteQueryParam ?? undefined,
      hasQuietanziante: this.hasQuietanzianteQueryParam === "SI" ? true : this.hasQuietanzianteQueryParam === "NO" ? false : undefined,
    };
  }

  /* METHODS */

  isChiusuraAnticipata(c: ContrattiReturned): ContrattiPDFTypes {
    return c.dataRispostaEmailChiusuraAnticipata && c.dataFirmaChiusuraAnticipata === null ? ContrattiPDFTypes.CHIUSURA_ANTICIPATA : ContrattiPDFTypes.CONTRATTO;
  }

  rowBackgrounds(item: Tuple): RowColors {
    if (this.isChiusuraAnticipata(item.reference) === ContrattiPDFTypes.CHIUSURA_ANTICIPATA) {
      return "soft-orange";
    }
    return "";
  }

  contrattoValueToTuple(contratto: ContrattiReturned): Tuple {
    return {
      id: contratto.id,
      dataInizio: contratto.dataInizio,
      dataFine: contratto.dataFine,
      nome: contratto.contrattiSuOspite?.[0].ospite?.persona?.nome ?? "",
      cognome: contratto.contrattiSuOspite?.[0].ospite?.persona?.cognome ?? "",
      tipoContratto: contratto.tipoContratto?.sigla ?? "",
      codiceFabbricato: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.fabbricato?.codice ?? "",
      unitaImmobiliare: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.unitaImmobiliare ?? "",
      numeroStanza: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.numeroStanza ?? "",
      postiLetto: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto
        ? contratto.contrattiSuOspite[0].contrattiSuOspiteSuPostoLetto.map((pl) => pl.postoLetto).join(", ")
        : "",
      dataRisposta: contratto.dataRispostaEmail,
      reference: contratto,
      dataChiusuraAnticipata: contratto.dataChiusuraAnticipata,
      idQuietanziante: contratto.idQuietanziante,
    };
  }

  getIdFromValue(value: Tuple): number {
    return value.reference.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteContratto(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: ContrattiCreateBody): Promise<{ id: number; details: null; push: boolean }> {
    const id = await this.createContratto(value);
    return {
      id,
      details: null,
      push: false,
    };
  }

  async updateHandler(id: number, value: ContrattiReplaceBody, isTableEdit: boolean): Promise<void> {
    await this.updateContratto(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  updateBodyFromValue(value: Tuple): ContrattiReplaceBody {
    return {
      dataInizio: value.reference.dataInizio,
      dataFine: value.reference.dataFine,
      checkout: !!value.reference.checkout,
      cauzione: !!value.reference.cauzione,
      tipoRata: value.reference.tipoRata,
      idTariffa: value.reference.idTariffa,
      idTipoContratto: value.reference.idTipoContratto,
      idQuietanziante: value.reference.idQuietanziante,
      ospiti: value.reference.contrattiSuOspite
        ? value.reference.contrattiSuOspite.map((cso) => ({
            idOspite: cso.idOspite,
            postiLetto: cso.contrattiSuOspiteSuPostoLetto ? cso.contrattiSuOspiteSuPostoLetto.map((pl) => pl.idPostoLetto) : [],
          }))
        : [],
      note: value.reference.note,
    };
  }
  async tupleValueFromCreateBody(id: number): Promise<Tuple> {
    const contratto = await this.getContratto(id, {
      tipoContratto: true,
      contrattiSuOspite: {
        ospite: { persona: true },
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: true,
        },
      },
    });
    return this.contrattoValueToTuple(contratto);
  }
  async tupleValueFromUpdateBody(id: number): Promise<Tuple> {
    const contratto = await this.getContratto(id, {
      tipoContratto: true,
      contrattiSuOspite: {
        ospite: { persona: true },
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: true,
        },
      },
    });
    return this.contrattoValueToTuple(contratto);
  }

  removeItemById(id: number | null): void {
    const index = this.contratti.findIndex((contratto) => contratto.id === id);
    if (index !== -1) {
      this.contratti.splice(index, 1);
    }
  }

  async downloadContratto(tuple: Tuple): Promise<void> {
    try {
      const filename = this.isChiusuraAnticipata(tuple.reference) ? `${tuple.id}_chiusura.pdf` : `${tuple.id}.pdf`;
      const path = this.$stored.getPath(`contratti/${filename}`);
      const response = await axios.get(path, { responseType: "blob" });
      const blob: Blob = response.data;

      const downloadFilename = this.isChiusuraAnticipata(tuple.reference) ? `Chiusura_anticipata_firmato_${tuple.id}.pdf` : `Contratto_firmato_${tuple.id}.pdf`;
      downloadBlob(blob, downloadFilename);
    } catch (error) {
      this.$store.dispatch(ActionTypes.ALERT, { message: `File non trovato`, alertType: AlertType.ERRORS_QUEUE });
    }
  }

  async answerContratto(tuple: Tuple, index: number, answer: boolean): Promise<void> {
    const type = this.isChiusuraAnticipata(tuple.reference);
    await this.answerContrattoFirmaFromEmail(tuple.id, { accettato: answer }, type, AlertType.ERRORS_QUEUE);
    this.removeItemById(tuple.id);
    this.$store.dispatch(ActionTypes.SET_TOAST, { message: `Risposta inviata`, color: "success" });
    this.contratti.splice(index, 1);
  }

  async fetchContratti(): Promise<void> {
    try {
      this.tableLoading = true;
      this.contratti = await this.getContrattiDaVisionare({
        tipoContratto: true,
        contrattiSuOspite: {
          ospite: { persona: true },
          contrattiSuOspiteSuPostoLetto: {
            postoLetto: true,
          },
        },
        ...this.queryParams,
      });
    } finally {
      this.tableLoading = false;
    }
  }

  /* WATCH */

  @Watch("contratti")
  watchContratti() {
    this.values = this.contratti.map((c) => this.contrattoValueToTuple(c));
  }

  @Watch("queryParams", { deep: true })
  async watchQueryParams() {
    await this.fetchContratti();
  }

  /* LIFE CYCLE */

  async mounted() {
    await this.fetchContratti();
  }
}
