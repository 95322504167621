








































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody, Quietanziante } from "operatn-api-client";

import QuietanzianteHandlerMixin from "@/mixins/handlers/QuietanzianteHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnQuietanziante extends Mixins(QuietanzianteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private quietanzianti: Quietanziante[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get selectedQuietanziante(): null | Quietanziante {
    return this.quietanzianti.find((q) => q.id === this.internalValue.idQuietanziante) ?? null;
  }

  /* LIFE CYCLE */

  async mounted() {
    this.quietanzianti = await this.getQuietanzianti();
  }
}
