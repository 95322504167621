




















import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody, OspitiReturned, TipoStanza } from "operatn-api-client";

import OspiteHandlerMixin from "@/mixins/handlers/OspiteHandlerMixin";
import PostoLettoHandlerMixin from "@/mixins/handlers/PostoLettoHandlerMixin";
import TipoStanzaHandlerMixin from "@/mixins/handlers/TipoStanzaHandlerMixin";

import OperatnBaseTable, { Actions, Column } from "@/components/gears/bases/OperatnBaseTable.vue";
import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnOspiteStanza, { Value } from "./OperatnOspiteStanza.vue";
import { AlertType } from "@/store";

interface Tuple {
  nomeOspite: string;
  cognomeOspite: string;
  sessoOspite: string;
  codiceFabbricato: string;
  nomeFabbricato: string;
  unitaImmobiliare: string;
  numeroStanza: string;
  postiLetto: string;
  tipoStanza: string;
}

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnBaseTable,
    OperatnOspiteStanza,
    OperatnActionDialog,
  },
})
export default class OperatnOspitiStanze extends Mixins(OspiteHandlerMixin, PostoLettoHandlerMixin, TipoStanzaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private tipiStanza: TipoStanza[] = [];

  private ospitiPostiLetto: Value[] = [];
  private ospitePostoLettoBody: Value | null = null;
  private showDialog = false;
  private addFormValid = false;

  private columns: Column<Tuple>[] = [
    {
      text: "Nome",
      value: "nomeOspite",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Cognome",
      value: "cognomeOspite",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Sesso",
      value: "sessoOspite",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Codice fabbricato",
      value: "codiceFabbricato",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Nome fabbricato",
      value: "nomeFabbricato",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Tipo Stanza",
      value: "tipoStanza",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Numero stanza",
      value: "numeroStanza",
      groupable: false,
      editable: false,
      sortable: false,
    },
    {
      text: "Posti letto",
      value: "postiLetto",
      groupable: false,
      editable: false,
      sortable: false,
    },
  ];
  private actions: Actions<Tuple> = {
    onDelete: (_, index) => {
      this.ospitiPostiLetto.splice(index, 1);
    },
  };

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get tuples(): Tuple[] {
    return this.ospitiPostiLetto.map((el) => this.valueToTuple(el));
  }

  /* METHODS */

  valueToTuple(value: Value): Tuple {
    return {
      nomeOspite: value.ospite?.nome ?? "",
      cognomeOspite: value.ospite?.cognome ?? "",
      sessoOspite: value.ospite?.sesso ?? "",
      codiceFabbricato: value.postiLetto[0].codiceFabbricato,
      nomeFabbricato: value.postiLetto[0].nomeFabbricato,
      unitaImmobiliare: value.postiLetto[0].unitaImmobiliare,
      numeroStanza: value.postiLetto[0].numeroStanza,
      postiLetto: value.postiLetto.map((pl) => pl.postoLetto).join(", "),
      tipoStanza: value.postiLetto[0].tipoStanza
    };
  }

  dialogCancel(): void {
    this.ospitePostoLettoBody = null;
  }

  async dialogConfirm(): Promise<void> {
    if (this.addFormValid) {
      this.ospitiPostiLetto.push(this.ospitePostoLettoBody as Value);
    }

    this.ospitePostoLettoBody = null;
    this.showDialog = false;
  }

  /* WATCH */

  @Watch("ospitiPostiLetto")
  async watchOspitiPostiLetto() {
    this.internalValue.ospiti = this.ospitiPostiLetto.map((el) => ({
      idOspite: (el.ospite as OspitiReturned).id,
      postiLetto: el.postiLetto.map((pl) => pl.idPostoLetto),
    }));

    if (this.internalValue.ospiti.length === 1) {
      let idTipoStanza: number | null = null;
      let idTipoFabbricato: number | null = null;

      if (this.internalValue.ospiti[0].postiLetto.length > 1) {
        idTipoStanza = this.tipiStanza.find(ts => ts.tipoStanza === 'SINGOLA')?.id ?? 1;
      } else {
        const idPostoLetto = this.internalValue.ospiti[0].postiLetto[0];
        const postoLetto = await this.getPostoLettoGeneral(idPostoLetto, { stanza: { tipoStanza: true, fabbricato: { tipoFabbricato: true } } });
        idTipoStanza = postoLetto.stanza?.idTipoStanza ?? null;
        idTipoFabbricato = postoLetto.stanza?.fabbricato?.idTipoFabbricato ?? null;
      }


      this.$emit("tipoStanza", idTipoStanza);
      this.$emit("tipoFabbricato", idTipoStanza);
    }

    this.$emit("update:formValid", !!this.internalValue.ospiti.length);
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiStanza = await this.getTipiStanza();

    if (this.internalValue.ospiti?.length) {
      this.ospitiPostiLetto = await Promise.all(
        this.internalValue.ospiti.map(async (el) => {
          const ospite = await this.getOspite(el.idOspite, { persona: true }, AlertType.ERRORS_QUEUE);
          const postiLetto = await Promise.all(el.postiLetto.map(async (pl) => this.getPostoLettoGeneral(pl, { stanza: { tipoStanza: true,  fabbricato: true } }, AlertType.ERRORS_QUEUE)));
          return {
            ospite,
            postiLetto: postiLetto.map((pl) => ({
              idPostoLetto: pl.id,
              postoLetto: pl.postoLetto,
              idStanza: pl.idStanza,
              unitaImmobiliare: pl.stanza?.unitaImmobiliare ?? "",
              numeroStanza: pl.stanza?.numeroStanza ?? "",
              idFabbricato: pl.stanza?.idFabbricato ?? -1,
              codiceFabbricato: pl.stanza?.fabbricato?.codice ?? "",
              nomeFabbricato: pl.stanza?.fabbricato?.nome ?? "",
              tipoStanza: pl.stanza?.tipoStanza?.tipoStanza ?? ""
            })),
          };
        })
      );
    }
  }
}
