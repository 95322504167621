














































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { ContoRicavi, Quietanziante, TipiBollettaCreateBody, TipiBollettaReplaceBody } from "operatn-api-client";

import ContoRicaviHandlerMixin from "@/mixins/handlers/ContoRicaviHandlerMixin";
import QuietanzianteHandlerMixin from "@/mixins/handlers/QuietanzianteHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnTipoBollettaForm extends Mixins(ContoRicaviHandlerMixin, QuietanzianteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<TipiBollettaCreateBody> | Partial<TipiBollettaReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  tipiBollettaValues!: string[];

  /* DATA */

  private contiRicavi: ContoRicavi[] = [];
  private quietanzianti: Quietanziante[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<TipiBollettaCreateBody> | Partial<TipiBollettaReplaceBody> {
    return {
      tipoBolletta: undefined,
      siglaCausale: undefined,
      importoCanone: null,
      importoConsumi: null,
      idContoRicaviConsumi: null,
      idContoRicaviCanoni: null,
      idQuietanziante: null,
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.contiRicavi = await this.getContiRicavi();
    this.quietanzianti = await this.getQuietanzianti();
  }
}
