




























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { UtentiUpdateBody, UtentiUpdatePasswordBody, UtentiUpdateRuoloBody } from "operatn-api-client";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnUtenteEditForm extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: UtentiUpdateBody & UtentiUpdateRuoloBody & UtentiUpdatePasswordBody;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  utentiNomiUtente!: string;

  @Prop({ type: Boolean, required: true })
  canChangePassword!: boolean;

  /* DATA */

  private showPassword = false;

  /* GETTERS AND SETTERS */

  get passwordType(): "text" | "password" {
    return this.showPassword ? "text" : "password";
  }
  get passwordIcon(): "mdi-eye" | "mdi-eye-off" {
    return this.showPassword ? "mdi-eye-off" : "mdi-eye";
  }

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyUtente(): UtentiUpdateBody & UtentiUpdateRuoloBody & UtentiUpdatePasswordBody {
    return {
      nomeUtente: "",
      email: "",
      ruolo: "",
      password: "",
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyUtente();
    }
  }
}
