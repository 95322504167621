





















































































import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  ContrattiCreateBody,
  ContrattiFilterParams,
  ContrattiIncludeParams,
  ContrattiReplaceBody,
  ContrattiReturned,
  ContrattiPDFTypes,
  RuoloUtente,
} from "operatn-api-client";

import { downloadBlob } from "@/utils";

import { ActionTypes, AlertType } from "@/store";
import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import ContrattoHandlerMixin from "@/mixins/handlers/ContrattoHandlerMixin";
import { RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnContrattoForm from "@/components/gears/forms/contratto/OperatnContrattoForm.vue";
import OperatnContrattoFirmaForm from "@/components/gears/forms/contratto/OperatnContrattoFirmaForm.vue";
import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnOspiteInput from "@/components/gears/inputs/OperatnOspiteInput.vue";
import OperatnConfirmDialog from "@/components/gears/dialogs/OperatnConfirmDialog.vue";

interface Tuple {
  id: number;
  dataInizio: Date;
  dataFine: Date;
  nome: string;
  cognome: string;
  codiceFabbricato: string;
  unitaImmobiliare: string;
  numeroStanza: string;
  postiLetto: string;
  dataInserimento: Date;
  dataChiusuraAnticipata: Date | null;
  reference: ContrattiReturned;
  tipoContratto: string;
  idQuietanziante: number;
}

enum ContrattoLanguage {
  AUTO = "",
  ITALIAN = "it",
  ENGLISH = "en",
}

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnContrattoForm,
    OperatnContrattoFirmaForm,
    OperatnDateInput,
    OperatnOspiteInput,
    OperatnConfirmDialog,
  },
})
export default class ContrattiDaFirmare extends Mixins<ResourceManagerMixin<Tuple, ContrattiCreateBody, ContrattiReplaceBody, number> & ContrattoHandlerMixin>(
  ResourceManagerMixin,
  ContrattoHandlerMixin
) {
  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questo contratto?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare i contratti selezionati?";

  private contratti: ContrattiReturned[] = [];
  private dateQueryParams: Pick<ContrattiFilterParams, "dataInizio" | "dataFine"> = {
    dataInizio: undefined,
    dataFine: undefined,
  };
  private ospiteQueryParam: number | null = null;
  private hasQuietanzianteQueryParam: string | null = null;
  private selectAction: "pdf" | "email" = "pdf";
  private tableLoading = false;

  private contrattoDaFirmare: Tuple | null = null;
  private contrattoFirmato: File | null = null;
  private contrattoFirmatoValid = false;
  private showFirmaDialog = false;

  private emailConfirmText = "";
  private showEmailConfirm = false;
  private tupleToSend: Tuple[] | null = null;

  private selectedLanguage = ContrattoLanguage.AUTO;
  private languagesItems = [
    {
      text: "Auto",
      value: ContrattoLanguage.AUTO,
      icon: "$european",
    },
    {
      text: "Italiano",
      value: ContrattoLanguage.ITALIAN,
      icon: "$italian",
    },
    {
      text: "Inglese",
      value: ContrattoLanguage.ENGLISH,
      icon: "$english",
    },
  ];

  /* GETTERS AND SETTERS */

  get canAdd(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get canEdit(): boolean {
    return [RuoloUtente.ROOT, RuoloUtente.ADMIN, RuoloUtente.SPORTELLO].includes(this.$store.getters.role);
  }

  get columns(): Column<Tuple>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Inserimento",
        value: "dataInserimento",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,
        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "Data inizio",
        value: "dataInizio",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "Data fine",
        value: "dataFine",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => value.toLocaleDateString(),
      },
      {
        text: "C. anticipata",
        value: "dataChiusuraAnticipata",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value) => (value ? value.toLocaleDateString() : "NO"),
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,
        editable: false,
      },
      {
        text: "Cognome",
        value: "cognome",
        groupable: false,
        editable: false,
      },
      {
        text: "Tipo",
        value: "tipoContratto",
        groupable: false,
        editable: false,
      },
      {
        text: "Fabbricato",
        value: "codiceFabbricato",
        groupable: false,
        editable: false,
      },
      {
        text: "N. stanza",
        value: "numeroStanza",
        groupable: false,
        editable: false,
      },
      {
        text: "Q",
        value: "idQuietanziante",
        groupable: false,
        editable: false,
        itemIcon: true,
        itemIconHandler: (value) => (value !== 1 ? "mdi-check" : ""),
        itemTextHandler: () => "",
        itemIconColour: () => "success",
      },
    ];
  }

  get exportedHeaders() {
    return this.columns ? this.getExportHeadersFromCols(this.columns) : {};
  }

  get actions(): Actions<Tuple> {
    return {
      onView: (item) => `../contratti/${item.id}`,
      others: [
        {
          icon: "mdi-file-pdf-box",
          color: "red",
          title: "Scarica contratto",
          action: (item) => this.downloadContratto(item),
        },
        {
          icon: "mdi-email-fast",
          color: "indigo",
          title: "Invia email",
          showAction: (item) => item.reference.dataRispostaEmail === null && this.canEdit,
          action: (item) => this.sendEmail(item),
        },
        {
          icon: "mdi-upload",
          color: "green",
          title: "Carica",
          action: (item) => this.openFirmaContratto(item),
          showAction: () => this.canEdit,
        },
      ],
    };
  }

  get queryParams(): ContrattiFilterParams {
    return {
      ...this.dateQueryParams,
      idOspite: this.ospiteQueryParam ?? undefined,
      hasQuietanziante: this.hasQuietanzianteQueryParam === "SI" ? true : this.hasQuietanzianteQueryParam === "NO" ? false : undefined,
    };
  }

  /* METHODS */

  isChiusuraAnticipata(c: ContrattiReturned): ContrattiPDFTypes {
    return c.dataChiusuraAnticipata && c.dataFirmaChiusuraAnticipata === null ? ContrattiPDFTypes.CHIUSURA_ANTICIPATA : ContrattiPDFTypes.CONTRATTO;
  }

  rowBackgrounds(item: Tuple): RowColors {
    if (item.reference.dataInvioEmail) {
      return "soft-blue";
    }
    if (this.isChiusuraAnticipata(item.reference) === ContrattiPDFTypes.CHIUSURA_ANTICIPATA) {
      return "soft-orange";
    }

    return "";
  }

  contrattoValueToTuple(contratto: ContrattiReturned): Tuple {
    return {
      id: contratto.id,
      dataInizio: contratto.dataInizio,
      dataFine: contratto.dataFine,
      nome: contratto.contrattiSuOspite?.[0].ospite?.persona?.nome ?? "",
      cognome: contratto.contrattiSuOspite?.[0].ospite?.persona?.cognome ?? "",
      codiceFabbricato: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.fabbricato?.codice ?? "",
      unitaImmobiliare: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.unitaImmobiliare ?? "",
      numeroStanza: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto?.[0].postoLetto?.stanza?.numeroStanza ?? "",
      postiLetto: contratto.contrattiSuOspite?.[0].contrattiSuOspiteSuPostoLetto
        ? contratto.contrattiSuOspite[0].contrattiSuOspiteSuPostoLetto.map((pl) => pl.postoLetto).join(", ")
        : "",
      dataInserimento: contratto.dataInserimento,
      reference: contratto,
      tipoContratto: contratto.tipoContratto?.sigla ?? "",
      dataChiusuraAnticipata: contratto.dataChiusuraAnticipata,
      idQuietanziante: contratto.idQuietanziante,
    };
  }

  getIdFromValue(value: Tuple): number {
    return value.reference.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteContratto(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: ContrattiCreateBody): Promise<number> {
    return this.createContratto(value);
  }

  async updateHandler(id: number, value: ContrattiReplaceBody, isTableEdit: boolean): Promise<void> {
    await this.updateContratto(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  updateBodyFromValue(value: Tuple): ContrattiReplaceBody {
    return {
      dataInizio: value.reference.dataInizio,
      dataFine: value.reference.dataFine,
      checkout: !!value.reference.checkout,
      cauzione: !!value.reference.cauzione,
      tipoRata: value.reference.tipoRata,
      idTariffa: value.reference.idTariffa,
      idTipoContratto: value.reference.idTipoContratto,
      idQuietanziante: value.reference.idQuietanziante,
      ospiti: value.reference.contrattiSuOspite
        ? value.reference.contrattiSuOspite.map((cso) => ({
            idOspite: cso.idOspite,
            postiLetto: cso.contrattiSuOspiteSuPostoLetto ? cso.contrattiSuOspiteSuPostoLetto.map((pl) => pl.idPostoLetto) : [],
          }))
        : [],
      note: value.reference.note,
    };
  }
  async tupleValueFromCreateBody(id: number): Promise<Tuple> {
    const contratto = await this.getContratto(id, {
      tipoContratto: true,
      contrattiSuOspite: {
        ospite: { persona: true },
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: true,
        },
      },
    });
    return this.contrattoValueToTuple(contratto);
  }
  async tupleValueFromUpdateBody(id: number): Promise<Tuple> {
    const contratto = await this.getContratto(id, {
      tipoContratto: true,
      contrattiSuOspite: {
        ospite: { persona: true },
        contrattiSuOspiteSuPostoLetto: {
          postoLetto: true,
        },
      },
    });
    return this.contrattoValueToTuple(contratto);
  }

  removeItemById(id: number | null): void {
    const index = this.contratti.findIndex((contratto) => contratto.id === id);
    if (index !== -1) {
      this.contratti.splice(index, 1);
    }
  }

  async downloadContratto(tuple: Tuple): Promise<void> {
    const id = tuple.reference.id;
    const type = this.isChiusuraAnticipata(tuple.reference);
    const blob = await this.getPdfContratto(id, type, this.selectedLanguage ?? undefined);
    downloadBlob(blob, type === ContrattiPDFTypes.CONTRATTO ? `Contratto_${id}.pdf` : `Chiusura_anticipata_${id}.pdf`);
  }

  async sendEmail(tuple: Tuple | Tuple[]): Promise<void> {
    if (Array.isArray(tuple)) {
      const emails = tuple.map((t) => t.reference.contrattiSuOspite?.find((x) => x)?.ospite?.email).filter((e) => !!e);
      if (emails.length) {
        this.emailConfirmText = `Sei sicuro di voler inviare ${emails.length} email?`;
        this.showEmailConfirm = true;
        this.tupleToSend = tuple;
      }
    } else {
      const email = tuple.reference.contrattiSuOspite?.find((x) => x)?.ospite?.email;
      if (email) {
        this.emailConfirmText = `Sei sicuro di voler inviare una email a ${email}`;
        this.showEmailConfirm = true;
        this.tupleToSend = [tuple];
      }
    }
  }

  async sendEmailConfirmed(answer: boolean) {
    this.showEmailConfirm = false;
    this.emailConfirmText = "";
    if (answer && this.tupleToSend && this.tupleToSend.length) {
      let nErrors = 0;
      for (const tuple of this.tupleToSend) {
        const type = this.isChiusuraAnticipata(tuple.reference);
        try {
          await this.sendContrattoEmailFirma(tuple.id, type, this.selectedLanguage ?? undefined, AlertType.ERRORS_QUEUE);
        } catch (error) {
          nErrors++;
        }
      }
      if (nErrors === 0) {
        this.$store.dispatch(ActionTypes.SET_TOAST, { message: `Email inviate con successo`, color: "success" });
      } else {
        this.$store.dispatch(ActionTypes.SET_TOAST, { message: `Ci sono stati errori nell'inviare le email`, color: "error" });
      }
    }
    this.tupleToSend = null;
  }

  async selectButtonPressed(): Promise<void> {
    switch (this.selectAction) {
      case "pdf":
        for (const tuple of this.selectedValues) {
          await this.downloadContratto(tuple);
        }
        break;
      case "email":
        await this.sendEmail(this.selectedValues);
        break;
    }
    this.selectedValues = [];
  }

  openFirmaContratto(tuple: Tuple): void {
    this.contrattoDaFirmare = tuple;
    this.showFirmaDialog = true;
    this.contrattoFirmato = null;
    this.contrattoFirmatoValid = false;
  }
  firmaDialogCancel(): void {
    this.showFirmaDialog = false;
    this.contrattoDaFirmare = null;
    this.contrattoFirmato = null;
  }
  async firmaDialogConfirm(): Promise<void> {
    if (this.contrattoFirmatoValid) {
      try {
        const formData = new FormData();
        formData.append("contratto", this.contrattoFirmato as File);
        if (this.contrattoDaFirmare) {
          const type = this.isChiusuraAnticipata(this.contrattoDaFirmare.reference) ? ContrattiPDFTypes.CHIUSURA_ANTICIPATA : ContrattiPDFTypes.CONTRATTO;
          await this.uploadContrattoFirma(this.contrattoDaFirmare.id as number, formData, type);
          this.removeItemById(this.contrattoDaFirmare?.id);
        }
      } finally {
        this.firmaDialogCancel();
      }
    } else {
      this.firmaDialogCancel();
    }
  }

  async fetchContratti(): Promise<void> {
    try {
      this.tableLoading = true;

      const includeParames: ContrattiIncludeParams & ContrattiFilterParams = {
        tipoContratto: true,
        contrattiSuOspite: {
          ospite: { persona: true },
          contrattiSuOspiteSuPostoLetto: {
            postoLetto: true,
          },
        },
        ...this.queryParams,
      };

      this.contratti = [...(await this.getContrattiDaFirmare(includeParames)), ...(await this.getContrattiDaFirmareChiusura(includeParames))];
    } finally {
      this.tableLoading = false;
    }
  }

  /* WATCH */

  @Watch("contratti")
  watchContratti() {
    this.values = this.contratti.map((c) => this.contrattoValueToTuple(c));
  }

  @Watch("queryParams", { deep: true })
  async watchQueryParams() {
    await this.fetchContratti();
  }

  /* LIFE CYCLE */

  async mounted() {
    await this.fetchContratti();
  }
}
