












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput
  }
})
export default class OperatnContrattoChiudiForm extends Vue {
  /* PROPS */

  @Prop({ validator: v => v instanceof Date || v === null, default: null })
  value!: Date | null;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Date, required: true })
  dataInizioContratto!: Date;

  @Prop({ type: Date, required: true })
  dataFineContratto!: Date;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get handledDataInizio(): Date {
    return this.dataInizioContratto;
  }

}
