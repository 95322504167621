
























































































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody, Quietanziante } from "operatn-api-client";
import { AlertType } from "@/store";

import QuietanzianteHandlerMixin from "@/mixins/handlers/QuietanzianteHandlerMixin";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnDatiContratto from "./gears/OperatnDatiContratto.vue";
import OperatnQuietanziante from "./gears/OperatnQuietanziante.vue";
import OperatnTipoContratto from "./gears/OperatnTipoContratto.vue";
import OperatnTariffa from "./gears/OperatnTariffa.vue";
import OperatnTipoRata from "./gears/OperatnTipoRata.vue";
import OperatnOspitiStanze from "./gears/OperatnOspitiStanze.vue";
import OperatnNote from "./gears/OperatnNote.vue";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
    OperatnDatiContratto,
    OperatnQuietanziante,
    OperatnTipoContratto,
    OperatnTariffa,
    OperatnTipoRata,
    OperatnOspitiStanze,
    OperatnNote,
  },
})
export default class OperatnContrattoForm extends Mixins(QuietanzianteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private detailsExpanded = [0, 1, 2, 3, 4, 5, 6];
  private datiContrattoValid = false;
  private quietanzianteValid = false;
  private tipoContrattoValid = false;
  private tariffaValid = false;
  private tipoRataValid = false;
  private ospitiPostiLettoValid = false;

  private quietanzianteDefault: Quietanziante | null = null;

  private idTipoStanzaForTariffa: null | number = null;
  private idTipoFabbricatoForTariffa: null | number = null;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody> {
    return {
      dataInizio: undefined,
      dataFine: undefined,
      checkout: true,
      cauzione: true,
      tipoRata: undefined,
      idTariffa: undefined,
      idTipoContratto: undefined,
      idQuietanziante: this.quietanzianteDefault?.id ?? undefined,
      ospiti: [],
      note: null,
    };
  }

  computeFormValid(): boolean {
    return this.datiContrattoValid && this.quietanzianteValid && this.tipoContrattoValid && this.tariffaValid && this.tipoRataValid && this.ospitiPostiLettoValid;
  }

  changedTipoStanza(idTipoStanza: number | null): void {
    this.idTipoStanzaForTariffa = idTipoStanza;
  }
  changedTipoFabbricato(idTipoFabbricato: number | null): void {
    this.idTipoFabbricatoForTariffa = idTipoFabbricato;
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.internalValue === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  @Watch("datiContrattoValid")
  watchDatiContrattoValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("quietanzianteValid")
  watchQuietanzianteValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("tipoContrattoValid")
  watchTipoContrattoValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("tariffaValid")
  watchTariffaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("tipoRataValid")
  watchTipoRataValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("ospitiPostiLettoValid")
  watchOspitiPostiLettoValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }

  /* LIFE CYCLE */

  async mounted() {
    if (this.internalValue === null) {
      this.quietanzianteDefault = await this.getQuietanzianteByValue("Beneficiario", AlertType.ERRORS_QUEUE);
      this.internalValue = this.getEmptyValue();
    }
  }
}
