












































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Fabbricato, Ospite, PostoLetto, Stanza, TipoStanza } from "operatn-api-client";
import { AlertType } from "@/store";

import CopyMixin from '@/mixins/CopyMixin';
import TipoStanzaHandlerMixin from '@/mixins/handlers/TipoStanzaHandlerMixin';

type PostoLettoProp = PostoLetto & { stanza: Stanza & { fabbricato: Fabbricato } };

@Component
export default class OperatnOspiteInfo extends Mixins(CopyMixin, TipoStanzaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  ospite!: Ospite;

  @Prop({ type: Array, default: [] })
  postiLetto!: PostoLettoProp[];

  /* DATA */

  private parsedPostiLetto: PostoLettoProp[] = [];
  private tipiStanza: TipoStanza[] = [];

  /* METHODS */

  openFabbricato(id: number) {
    this.$router.push(`../fabbricati/${id}`);
  }

  openStanza(fid: number, sid: number) {
    this.$router.push(`../fabbricati/${fid}/stanze/${sid}`);
  }

  openOspite(id: number) {
    this.$router.push(`../ospiti/${id}`);
  }

  getTipoStanza(id: number): string {
    return this.tipiStanza.find(tipoStanza => tipoStanza.id === id)?.tipoStanza ?? 'NON TROVATO';
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiStanza = await this.getTipiStanza(AlertType.ERRORS_QUEUE);

    const stanza: { [idStanza: number]: PostoLettoProp[] } = {};
    for (const postoLetto of this.postiLetto) {
      if (stanza[postoLetto.stanza.id]) {
        stanza[postoLetto.stanza.id].push(postoLetto);
      } else {
        stanza[postoLetto.stanza.id] = [postoLetto];
      }
    }

    for (const s in stanza) {
      this.parsedPostiLetto.push(
        stanza[s].reduce((a, b) => {
          a.postoLetto = a.postoLetto + "," + b.postoLetto;
          return a;
        })
      );
    }
  }
}
