








































import { Component, Mixins, Prop } from "vue-property-decorator";
import { ContrattiCreateBody, ContrattiReplaceBody, TipiContrattoReturned } from "operatn-api-client";

import TipoContrattoHandlerMixin from "@/mixins/handlers/TipoContrattoHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnTipoContratto extends Mixins(TipoContrattoHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContrattiCreateBody> | Partial<ContrattiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private tipiContratto: TipiContrattoReturned[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get tipiContrattoItems() {
    return this.tipiContratto.map(tc => ({
      label: `${tc.sigla} - ${tc.nome}`,
      value: tc.id
    }))
  }

  get selectedTipoContratto(): null | TipiContrattoReturned {
    return this.tipiContratto.find((q) => q.id === this.internalValue.idTipoContratto) ?? null;
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiContratto = await this.getTipiContratto({ tipoStudente: true });
  }
}
