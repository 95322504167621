










































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { QuietanziantiCreateBody, QuietanziantiReplaceBody } from "operatn-api-client";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";

@Component({
  components: {
    OperatnDateInput,
  },
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnQuietanzianteForm extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: QuietanziantiCreateBody | QuietanziantiReplaceBody;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  quietanziantiValues!: string[];

  @Prop({ type: Array, default: () => [] })
  codiciEusisValues!: string[];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): QuietanziantiCreateBody | QuietanziantiReplaceBody {
    return {
      quietanziante: undefined as any,
      denominazione: null,
      sesso: null,
      dataNascita: null,
      comuneNascita: null,
      indirizzo: null,
      cap: null,
      citta: null,
      siglaProvincia: null,
      codiceFiscale: null,
      numeroTelefono: null,
      email: null,
      codiceEusis: undefined as any,
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }
}
