












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";


@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnContrattoFirmaForm extends Vue {
  /* PROPS */

  @Prop({ validator: v => v instanceof File || v === null, default: null })
  value!: File | null;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

}
